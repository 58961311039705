.query {
    margin: 3rem;
}

.queryHeading {
    margin: 2rem 1rem;
}

.querycontainer input{
    margin: 2rem 1rem;
    margin-bottom: 0rem;
}
.querycontainer textarea{
    margin: 2rem 1rem;
    margin-bottom: 0rem;
}


/* Button  */
.btn-three {
    color: #000;
    transition: all 0.5s;
    position: relative;
    font-size: 1.5rem;
    border: 1px solid #848484;
    border-radius: 5px;
    width: 200px;
    height: 70px;
    margin: 2rem 1rem;
    margin-bottom: 0;
}
.buttonDiv {
    padding:0 6rem;
}

.btn-three:hover {
    background-color: #b4b4b4;
    transition: all 0.3s;
    transform: scale(1,1);
}

.btn-inactive {
    color: #000;
    transition: all 0.5s;
    position: relative;
    background: transparent;
    font-size: 1.5rem;
    border: none;
    border-radius: 5px;
    width: 200px;
    height: 70px;
    margin: 0.5rem 1rem;
    margin-bottom: 0;
}
.btn-2 {
    margin: 0 1rem;
}


@media (max-width:530px){
    .buttonDiv {
        padding:0 2rem;
    }
}


@media (max-width:495px){
    .queryHeading {
        margin: 2rem 1rem;
    }
    .queryHeading h1 {
        font-size: 2rem;
    }
    .querycontainer input{
        margin: 2rem 0.5rem;
        margin-bottom: 0rem;
    }
    .querycontainer textarea{
        margin: 2rem 0.5rem;
        margin-bottom: 0rem;
    }
}