.join img {
    height: 400px;
    width: 500px;
    margin: 2rem;
}

.join h2 {
    font-size: 28px;
}

.join .btn {
    background-color: #316fb9;
    color: #ffffff;
    font-weight:600;
}

@media (max-width:600px){
    .join img {
        height: 250px;
        width: 300px;
        margin: 2rem;
    }
    .join h2 {
        margin: 1rem;
    }
}