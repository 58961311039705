.contact{
    background-color: black;
    color: white;
    width:100%;
}

/*row 1*/

.row-1{
    display:flex;
    justify-content: space-between;
    padding: 0% 1%;
}
.logo-alpha{
}
.logo-alpha img{
    height: auto;
    width: 600px;
    object-fit: contain;
    /* padding-bottom: 20%;
    padding-right: 50%; */
}

.headL{
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1%;
}
.headL h1{
    text-align: center;

    font-size: 2rem;
}
.Explore{
    padding:2%;
    margin-right: 25%;
    /* width: 105%; */
}

.links-1{
    margin-top: 90%;
    line-height: 2;
}

.Explore a{
    text-decoration: none;
    color: white;
    font-size: 22px;
    text-align: justify;
}
.useful-links{
    width: 100%;
}

.useful-links a{
    text-decoration: none;
    color: white;
    padding: 1%;
    font-size: 22px;    
}

.links-2{
    margin-top: 50%;
    line-height: 2;
    padding-right: 4%;
}

/*row 2  */

.row-2{
    display: flex;
    justify-content: space-between;
    padding: 0% 1%;
    margin-top: 0;

}

.copyright{
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 25%;
    margin-top:0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.copyright p{
    /* margin-top: 5%; */
    /* margin-left: 25%; */
    font-size: 23.5px;
    width: 60%;

}

.map{
    margin-right: 0%;
    margin-top: -10%;
    align-items: center;
    justify-content: center;
    }
    .map-wrap {
        width: 500px;
        height: 500px;
        border-radius: 100%;
         border: 1px solid #ccc;
    }
    
    .leaflet-container{
        width: 100%;
        height: 100%;
         animation: backInRight 1s 1.2s;
        animation-fill-mode: forwards;  
        border-radius: 50%;
    }

    .contact-numbers{
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        margin-right: 5%;
    }
    
    .links-3{
        margin-top: 100px;
        text-align: center;
        line-height: 2;
        font-size: 23.5px;
    }
    .combined{
        display: flex;
        margin-right: 5%;
    }
    .contact-numbers{
    
    }

/* row -3 */
 .row-3{
    display: flex;
    justify-content: space-between;
    padding: 0% 2%;
    padding-bottom: 1%;
    /* margin-top: -8%; */
 }

 .social-media h2{
    font-size: 1.5rem;
    align-items: center;
 }
 .icons{
    width: auto;
    margin-top: 35%;
 }
 .icon{
    height: 50px;
    width: 100px;
 }
 .designation{
    padding-left: 4%;
    font-size: 23.5px;
 }
 .contact-numbers{
   /* margin-left: 25%; */
 }
 .social-media{
    margin-right: 5%;
 }

 @media (max-width:1096px){
    .contact{
        position: relative;
    }
 }

 @media (max-width:1200px){
    .contact{
        right: 0;
    }
    .logo-alpha img{
        width: 300px;
    }
 }

 @media (max-width:990px){
    .contact{
    width: 100%;
    margin: 0;
    right:0 ;
    }
    .row-1{
        display:contents;
        }
    .logo-alpha{
        align-items: center;
        justify-content: center;
        display:flex;
    }
    .logo-alpha img{
        justify-content: center;
        width: 300px;
        height: auto;
        object-fit: contain;
    }
    .links-1{
        margin-top: 0;
        margin-left: 25%;
    }
    .Explore{
        margin-top: 0;
        margin-right: 0;
        width: 100%;
    }
    .Explore a{
        font-size: 1rem;
    }
    .headL{
        display: flex;
        margin-top: 0;
    }
    .headL h1{
       
        padding-top: 0;
        font-size: 2rem;
    }
    .map{
        margin-right: 0;
        align-items: center;
        justify-content: center;
        margin-top: 10%;
        display:grid;
    .map-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
        width: 300px;
    }
}
    .combined{
        display: flex;
        align-items: center;
        justify-content: center;

    }
    .links-2{
        margin-top: 0;
    }
    .useful-links{
        width: 100%;
        padding-left: 30%;
         align-self: center;
    }
    .useful-links a{
        font-size: 1rem;

    }
    .row-2{
        display: contents;
        margin-top: 0;
    }
    .contact-numbers{
        margin-right: 0;
        margin-top: 10%;
    }
    .links-3{
        margin-top: 0;
        font-size: 10px;
    }
    .row-3{
        
        margin-top: 10%;
        padding-bottom: 0%;
    }
    .designation{
        font-size: 10px;
        text-align: center;
        justify-content: right;
        
    }
    .social-media{
        justify-content: left;
        padding-top: 5%;
        padding-right: 6%;
    }
    .social-media h2{
        text-align: center;
    }
    .icons{
     align-items: center;
     /* justify-content: center; */
     /* padding-left: 10%;
     margin-top: 15%; */
    }
    .icon{
        height: 40px;

    }
    .copyright{
        display: none;
        align-items: center;
        justify-self: center;
        text-align: center;
        width: 100%;
        margin-top: 10%;
        padding-top: 5%;

    }
    .copyright p {
        margin-top: 0;
        align-items: center;
        font-size: 20px;
        display: hidden;
    }

    .combined{
        margin-right: 0;
    }
 }

 @media (max-width:1200px){
    .headL h1{
        font-size: 2rem;
    }
 }

 @media (max-width:1500px){

    .map-wrap{
        width: 400px;
        height: 400px;
    }
    .logo-alpha img{
        height: 300px;
        width: 300px;
    }

    .Explore a{
        font-size: 15px;
    }
    .useful-links a {
        font-size: 15px;
    }
    .contact-numbers{
        margin-right: 0;
    }
    .links-3{
        font-size: 15px;
    }
    .designation{
        font-size: 15px;
    }

    .copyright p{
        font-size: 15px;
    }
    .icon{
        width: 25px;
        padding-right: 30%;

    }
    .icons{
        box-sizing: border-box;
        display:flex;
        margin-top: 0;
        margin-left: 0;
    }
    .social-media{
        margin-right: 10%;
    }
    

 }

 @media (max-width:450px){
         .row-3{
            display: flex
         }
         .designation{
            text-align: left;
         }
 }

/* @media (max-width:1400px){
    .map-wrap{
        width: 400px;
        height: 400px;
    }
    .designation{
        font-size: 20px;
    }
} */
