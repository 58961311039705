*{
    font-family: 'Montserrat', sans-serif;
    box-sizing: border-box;
  }
  .heading{
    font-size: 22px;
    text-align: center;
  }
#about{
    z-index:2;
    margin: 2rem 6rem;
  }
#about h1 {
  font-size: 5rem;
}
  .Row-1{
    padding-top: 2%;
  }
  .logo-1 img{
  width: 700px;
  height: 500px;
  padding-right: 20%;
  
  }
  .Logo img {
  width: 524px;
  height: 294px;
  object-fit: contain;

  
  }
  .p-1{
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 35.2px;
    width: 100%;
    margin-right: 18%;
    line-height: 1.8;
    font-size: 22px;
    text-align: justify;
    /* height: 176px;
    width: 673px; */
  }
  .p-1 p{
    padding-top: 8%;
    padding-right: 8%;
  }
  .p-2 p{
    font-size: 22px;
    line-height:1.8;
    font-size: 25px;
   }
  
  .Row-2{
    padding-top: 5%;
    flex-direction:row-reverse
  }
  .l-2 {
    padding-left: 25%;
  }
  .l-2 img{
    width: 400px;
  }
  .Row-3{
    padding-top: 5%;
  }
  .p-3{
    line-height: 40px;
    width: 100%;
    font-size: 25px;
    /* height: 212px;
    width: 673px; */
  }
  .Logo-3{
    margin-top: 0px;
  }

  @media (max-width:1430px ) and (min-width:1140px)  {
    #about{
      margin-top: 10%;
    }
    #about h1{
      font-size: 4rem;
    }
    .logo-1 img{
      margin-right: 5%;
      width: 550px;
    }
    .Logo img{
      height: 200px;
      width: 450px;
      margin-top: 5%;
    }
    .l-2{
      padding-left: 20%;
    }
    .Logo-3{
      margin-top: 0;

    }
    .Logo-3 img{
      height: 300px;
      margin-right: 20%;
      /* width: 300px; */
    }
  }

@media (max-width:1140px ) and (min-width:990px) {
    #about{
      margin-top: 15%;
    }
    #about h1{
      font-size: 70px;
    }
    .Row-2{
      margin-top: 15%;
    }
    .logo-1 img{
      width: 500px;

    }

    .p-1 p{
      width: 100%;
      padding-right: 8%;
      font-size: 20px;
      /* line-height: 1.7; */
      text-align:justify

    }
    .Logo img{
      height: 250px;
      width: 400px;
      margin-left: -5%;
    }
    .p-2 p{
      text-align: justify;
      padding-left: 10%;
    }

    .l-2{
      padding-left: 20%;
    }

    .Logo-3{
      align-items: center;
      margin-left: 10%;
      margin-top: 25%;
      
    }

    .Logo-3 img {
      height: 300px;
      width: 300px;
    }
    .p-3 p{
      text-align: justify;
      padding-right: 5%;
    }

  }

  @media (max-width:990px){
    #about{
      margin-top: 40% ;
      margin-bottom:20%;
      margin-left: 2%;
      margin-right: 2%;
    }
    #about h1{
      font-size: 3.5rem;
      align-items: center;
      justify-content: center;
    }
    .Row-1{
      margin: 0;
      padding: 0;
    }
    .Row-2{
      display: flex;
    }
     .logo-1{
      align-items: center;
      display: flex;
      justify-content: center;
     }
    .logo-1 img {
       margin-top: 0;
       width:300px;
       padding-right: 0;
       justify-content: center;
    }
    .p-1{
      align-items: center;
      text-align: center;
      margin-left: 0;
    }
    .p-1 p {
      padding-right: 0;
    }

    .p-2 p{
      text-align: justify;
      line-height: 1.6;
      padding-left: 0;
      /* margin-bottom: 20%; */
    }

    .p-3{
      align-items: center;
      text-align: center;
      justify-content: center;
      margin-left: 0;
    }
    .p-3 p{
      font-size: 1.5rem;
      text-align: justify;
      padding-left: 0;
    }
    .l-2{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 0;
    }

    .l-2 img{
      display: flex;
      width:300px;
      margin-top: 0;
      align-items: center;
    }

    .Logo-3 img{
          margin-left:13%;
          width: 300px;
    }

    .Logo-3{
      display: flex;
      justify-content: center;
    }

    .Logo-3 img{
      margin-left: 0;
      margin-top: 0;
    }
    /* .p-3{
      margin-top: 5%;
    } */
    .p-1 p{
      font-size: 1.5rem;
      text-align: justify;

    }
    
  }

  @media (max-width:480px){
    #about{
      margin:0;
    }
    .logo-1 img{
      height: 200px;
      width: 200px;
    }
    .l-2 img{
      width: 200px;
    }
    .Logo-3 img{
      width:200px;
    }
    .p-1 p{
      font-size: 18px;
      line-height: 1.5;
    }
    .p-2 p {
      font-size: 18px;
      line-height: 1.5;
    }
    .p-3 p{
      font-size: 18px;
      line-height: 1.5;
    }
  }

  /* @media (max-width:480px){
    #about{
      margin-top: 50%;
    }

    #about h1{
      font-size: 2rem;
      margin-left: 5%;
    }
    .row-1{
      margin-top: -15%;
      
    }
    .row-2{
      margin-top: 35%;
    }
    .p-1{
      margin-left: 0;
      margin-top: -35%;
    }
    .p-1 p{
      justify-content:center;
      align-items:center;
      height: 100%;
      width: 100%;
      font-size:1rem;
      margin: 0 0 10px;
      display: block;
      text-align: justify;
      font:400 15px/1.8 Roboto , sans-serif;

    }
    .p-2{
       padding-left: 0;
       padding-right: 0;
    }
    .p-2 p{
      height: 100%;
      max-width: 100%;
      margin-top:100px;
      text-align:justify;
      font:400 15px/1.8 Roboto , sans-serif;
      
    }

    .l-2{
      display: flex;
      justify-content: center;
    }

    .l-2 img{
       width: 300px;
    }

    .logo{
      margin: 0;
    }
    .logo-1 img{
      width:290px;
      align-items:center;
      justify-content:center;
      margin-bottom: -70px;
      overflow: hidden;
      padding: 0%;
      margin-top: -12%;
      margin-left:50%;

    }

    .p-3{
      margin: 0 0 10px;
    }
    .p-3 p{
      text-align: justify;
      font:400 15px/1.8 Roboto , sans-serif;
    }

    .Logo-3 img{
      margin-top: 30%;
     margin-left:15%;
     margin-top:-30%;
     width:250px;
    }
    .row-3{
      margin-top: 50%;
    }

  } */
