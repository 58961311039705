#team h1 {
    font-size: 5rem;
    font-family: 'Montserrat', sans-serif;
    margin-top: 4rem;
}

#team .container{
    margin:2rem;
}

.member {
    padding: 0;
}

.member img {
    width:250px;
    height:250px;
    border-radius:100%;
    margin:2rem 0;
}

@media (max-width:1400px){
    .member img {
        width:200px;
        height:200px;
    }
}

@media (max-width:992px){
    .disableAtCondition{
        display: none;
    }
}

@media (max-width:700px){
    #team h1 {
        font-size: 3rem;
        margin-top: 3rem;
    }
}

@media (max-width:620px){
    #team .container{
        margin:0rem;
    }
}