.welcome-screen {
    background-color: #316fb9ff;
    /* Initial background color (white) */
    transition: background-color 2s ease;
    /* Add a transition effect to the background-color property */
    color: #316fb9ff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100vh;
    z-index: 2;
    width:100%
    /* width: 100vw; */
}

.welcome-screen::before {
    animation: ease-in-out 1s;
    z-index: 1;
}

.welcome-screen::after {
    animation: ease-in-out 1s;
    z-index: 1;
}

.welcome-screen.show-welcome {
    background-color: rgb(255, 255, 255);
    z-index: 1;
    transition: ease-out 1s, z-index 15s ease;
}

.home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100%;
}

.image {
    max-width: 100%;
    transition: transform 1s ease;
    z-index: 1;
}

.image.shifted {
    transform: translateX(80%);
    scale: 0.75;
    transition: ease-out 1s;
    z-index: 1;
}

.fade-in-container {
    opacity: 0;
    transition: opacity 1.5s ease-in-out;
}

.home-text {
    font-weight: 500;
}

.fade-in {
    opacity: 1;
    z-index: 1;
}

.content {
    color: black;
    position: absolute;
    top: 33%;
    left: 12%;
    text-align: left;
    width: 37%;
    font-size: 1.5rem;
}

.one {
    font-size: 3.6rem;
}

.big {
    background-color: #4678f9ff;
    font-size: 30px;
    text-decoration: none;
    position: relative;
    margin-top: 5%;
}

.big:hover {
    background-color: #316fb9ff;
    transition: background-color 0.5s ease;
}

@media (max-width: 500px) {
    .content {
        top: 20%;
        left: 2%;
        width: 100%;
        scale: 0.85;
    }

    .one {
        font-weight: 500;
        font-size: 2rem;
    }

    .home-text {
        font-weight: 400;
        font-size: 1.5rem;
    }

    .shifted,.imagefadeIn,.imagefadeOut {
        display: none;
    }

    .image{
        scale: 0.9;
        position: relative;
        top: -5%;
    }

    .big {
        font-size: 20px;
    }
}