.events {
    margin: 4rem 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}
.events .heading {
    font-size: 5rem;
}

/* Sliding Content  */
.slide-w {
    margin: 0;
    margin-top: 20rem;
    position: relative;
    display: block;
    width: 95%;
    height: 400px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    background-color: #ffffff;
    color: #000000;
}


.slide {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    min-width: 100%;
    height: 100%;
    font-size: 1rem;
}
.slide span {
    display: inline-block;
    padding-left: 100%;
    text-align: center;
    white-space: nowrap;
    min-width: 100%;
    height: 100%;
    /* line-height: 140px; */
    font-size: 85px;
    animation: slide 50s linear infinite;
}
.slide span img {
    height: 400px;
    width: auto;
    border-radius: 10px;
}
/* .slide span:hover{
    -webkit-animation-play-state:paused;
    -moz-animation-play-state:paused;
    -o-animation-play-state:paused;
    animation-play-state:paused;
    cursor: pointer;
} */

@keyframes slide {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

@media (max-width:850px){
    .slide-w {
        height: 300px;
    }
    
    .slide span img {
        height: 300px;
        width: auto;
        border-radius: 10px;
    }
}
@media (max-width:580px){
    .events .heading {
        font-size: 3rem;
    }
    .slide-w {
        margin-top: 18rem;
        margin-bottom: 0;
        height: 220px;
    }

    .slide span img {
        height: 220px;
        width: auto;
        border-radius: 10px;
    }
}
@media (max-width:410px){
    .slide-w {
        margin-top: 10rem;
        height: 200px;
    }
    .slide span img {
        height: 180px;
        width: auto;
        border-radius: 10px;
    }
}





/* PREVIOUS VERSION CODE  */

/* .d-block {
    width: 800px;
    height: 500px;
    border-radius: 10px;
}

.galleryCarousel {
    margin: 2rem 0;
    justify-content: center;
}

.carouselItem {
    margin:0;
    display:contents;
    align-items: center;
}

.imageCaptionBar{
    text-align: center;
    margin: 0;
    width: 798px;
    height: 80px;
    color: rgb(255, 255, 255);
    border-radius: 0 0 10px 10px;
    background-color: rgba(69, 69, 69, 0.8);
    opacity: 0.6;
    padding-bottom: 2rem;
}
.imageCaptionBar p{
    margin: 0 2rem;
    float: left;
}

.custom-carousel {
    width:900px;
    height: 650px;
    margin-top: 3rem;
}
.custom-carousel-item {
    justify-content: center;
    align-items: center;
    margin: 0;
} */

/* .text-container {
    display: none;
    margin: 0;
    width: 798px;
    height: 80px;
    color: #ffffff;
    border: 1px solid #000000;
    border-radius: 0 0 10px 10px;
    background-color: rgba(69, 69, 69, 0.8);
    opacity: 0.6;
    padding-bottom: 2rem;
}
.text-container p{
    margin: 0 2rem;
    float: left;
}


.socialMedia {
    display: none;
}
.socialMedia img{
    margin: 0;
}

.subCarouselItem{
    display: none;
    width:900px;
    height: 700px;
    margin: 0;
}
.custom-carousel:hover {
    width: 100%;
    background-color: rgb(186, 186, 186);
    transition: 0.5s;
}
.custom-carousel:hover .custom-carousel-item {
    height: 800px;
    transition: 0.5s;
}
.custom-carousel:hover .carouselItem{
    display: none;
    transition: 0.5s;
}
.custom-carousel:hover .text-container{
    display: unset;
    transition: 0.5s;
}
.custom-carousel:hover .subCarouselItem {
    display: unset;
}
.custom-carousel:hover .socialMedia {
    display: unset;
    transition: 0.5s;
    
} */
