nav {
  margin:  0;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
  /* background-color: ; */
  background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
  z-index: 2;
  align-items: center;
}

.logoImage {
  height: 5.5rem;
  margin: 0 3rem;
  padding: 1rem 0;
  position: absolute;
  left: 0;
  top: 0;
}

.navbar__ {
  margin: 0;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  height: 46px;
}


.navbarContainer a {
  color: #000000;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  margin: 0px 10px;
  position: relative;
  z-index: 0;
  cursor: pointer;
}

.toggle {
  position: fixed;
  top: 18px;
  right: 40px;
  z-index: 2;
  display: none;
}

.toggle a {
  text-decoration: none;
  color: #262626;
  font-size: 30px;
  cursor: pointer;
}

.crossMark {
  font-size: 2rem;
  cursor: pointer;
  position: fixed;
  top: 18px;
  right: 40px;
  z-index: 2;

}

.menu {
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  right: -150%;
  width: 500px;
  height: 100%;
  border-left: 0.05rem solid #656565;
  background: #FFF;
  z-index: 4;
  transition: 0.5s;
}

ul {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

ul li {
  list-style: none;
}

ul li a {
  padding: 8px;
  display: inline-block;
  font-family: verdana;
  font-size: 2em;
  text-decoration: none;
  text-transform: uppercase;
  color: #262626;
}


.menu.active {
  right: 0;
  overflow: auto;
}



.button {
  padding: 0.75rem 1.25rem;
  margin: 0 1rem;
  border-radius: 10rem;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 1rem;
  letter-spacing: 0.15rem;
  transition: all 0.3s;
  overflow: hidden;
  z-index: 1;
  position: absolute;
  right: 3rem;
}

.button:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #316fb9;
  border-radius: 10rem;
  z-index: -2;
}

.button:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  height: 100%;
  background-color: #4678f9ff;
  transition: all 0.3s;
  border-radius: 10rem;
  z-index: -1;
  cursor: pointer;
}

.button:hover {
  color: #fff;
}

.button:hover:before {
  width: 100%;
}

a {
  font-family: Arial;
  text-decoration: none;
  font-size: 20px;
}


.pill {
  background-color: #cccccc;
  border: none;
  color: black;
  padding: 0px 8px;
  text-align: center;
  text-decoration: none;
  margin: 5px 1px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
}


.navbarLinks {
  background-color: #cccccc;
  border: 1px solid #cccccc;
  color: black;
  padding: 4px 15px;
  text-align: center;
  display: inline-block;
  margin: 5px 3px;
  border-radius: 20px;
  text-decoration: none;
}

.navbarLinks:hover {
  background-color: #9d9d9d;
  transition-duration: 0.5s;
}


@media (max-width:1100px) {
  .button {
    right: 4rem;
    top: 1rem;
  }
}

@media (max-width:1096px) {
  .navbarContainer {
    display: none;
  }
  .contact {
    position: fixed;
    right: 4rem;
  }

  .toggle {
    display: unset;
  }
  
  .logo {
    margin: 0 2rem;
  }
  .pill {
    display: none;
  }

}

@media (max-width:995px) {
  .navbarContainer {
    margin: 0rem 1rem;
  }
}

@media (max-width:480px) {
  .menu {
    width: 100%;
  }
  
  .button {
    display: none;
  }
  
  .logoImage {
    height: 5rem;
    margin: 0 1rem;
  }
}